import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {searchFind} from '../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setInitialValues} from './historyModalSlice';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {Table, TableHead, TableBody, TableCell, TableRow, CircularProgress} from '@mui/material';

function HistoryTable(props) {
  const dispatch = useDispatch();

  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const [tableData, setTableData] = useState([]); // Partial data to render in table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    vehicles: [],
    tasks: [],
  });

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, filters, searchText]);

  function rowFilter(rowData, row) {
    const vehiclesInFilter = filters.vehicles.includes(rowData.vehicle) || filters.vehicles.length == 0;
    const taskInFilter = filters.tasks.includes(rowData.task) || filters.tasks.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return vehiclesInFilter && taskInFilter && search;
  }

  function handleFilters(select) {
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function filterTableRows(allTableData) {
    const tempTableData = allTableData.filter((rowData) => {
      return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }

    setTableData(tempTableData);
  }

  function generateTableData() {
    if (props.loading) {
      return (
        <TableBody id='table-body'>
          <TableRow>
            <TableCell colSpan={100}>
              <div className='m-2'>
                <div className='loader mx-auto'></div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let colorFlip = false;
    const paginatedData =
      rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData;

    return (
      <TableBody id='table-body'>
        {paginatedData.map((rowData, index) => {
          if (!rowData) {
            return null;
          }
          colorFlip = !colorFlip;
          const row = (
            <TableRow
              key={index}
              sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}
              onClick={async () => {
                if ($(window).width() <= 767) {
                  dispatch(setInitialValues(rowData));
                  $('#shopview-history-data-modal').modal('show');
                }
              }}
            >
              <TableCell>
                <div className='mb-2'>
                  <div className='text-secondary font-weight-bold'>{rowData.vehicle}</div>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <div className='text-secondary font-weight-bold'>{rowData.task}</div>
                </div>
                <div className='ml-2 text-xs'>
                  <div className='text-secondary' dangerouslySetInnerHTML={{__html: rowData.completedString}}></div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary'>{rowData.dateCompleted}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary'>{rowData.serviceEntry.scheduled ? 'Scheduled' : 'Unscheduled'}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Work Order: </div>
                  <div className='text-secondary d-inline'>{rowData.workOrder}</div>
                </div>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Invoice Number: </div>
                  <div className='text-secondary d-inline'>{rowData.invoice}</div>
                </div>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Labor Cost: </div>
                  <div className='text-secondary d-inline'>
                    {rowData.laborCost == '' ? '' : '$' + rowData.laborCost}
                  </div>
                </div>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Parts Cost: </div>
                  <div className='text-secondary d-inline'>
                    {rowData.partsCost == '' ? '' : '$' + rowData.partsCost}
                  </div>
                </div>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Labor Time: </div>
                  <div className='text-secondary d-inline'>
                    {rowData.laborTime == '' ? '' : rowData.laborTime + ' hrs'}
                  </div>
                </div>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Supplier Name: </div>
                  <div className='text-secondary d-inline'>{rowData.supplier}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Reviewed By: </div>
                  <div className='text-secondary d-inline'>{rowData.reviewedBy}</div>
                </div>
                <div>
                  <div className='text-secondary font-weight-bold d-inline'>Notes: </div>
                  <div className='text-secondary d-inline'>{rowData.notes}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell text-center align-right'>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm'
                    data-toggle='modal'
                    data-target='#shopview-edit-history-modal'
                    onClick={() => {
                      dispatch(setInitialValues(rowData));
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-cog' />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          );
          return row;
        })}
      </TableBody>
    );
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell width='20%'>
            <TableHeadFilterButtonTitleWrapper title='Equipment'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#shopview-history-vehicle-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.vehicles.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='shopview-history-vehicle-select'
                name='vehicles'
                multiple={true}
                search={true}
                value={filters.vehicles}
                options={props.filterOptions.vehicles}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell width='20%'>
            <TableHeadFilterButtonTitleWrapper title='Service'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#shopview-history-task-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.tasks.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='shopview-history-task-select'
                name='tasks'
                multiple={true}
                search={true}
                value={filters.tasks}
                options={props.filterOptions.tasks}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='15%'>
            Date
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='15%'>
            Type
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='20%'>
            Service Details
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='20%'>
            Additional Details
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='5%'></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div className='table-responsive'>
      <TabMenuTableWrapper
        table={
          <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
            {generateTableHeaders()}
            {generateTableData()}
          </Table>
        }
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
    </div>
  );
}

export {HistoryTable};
