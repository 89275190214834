import {configureStore, combineReducers} from '@reduxjs/toolkit';
import appReducer from '../appSlice';
import frameworkReducer from '../components/Framework/frameworkSlice';
import cabviewReducer from '../features/Cabview/cabviewSlice';
import cropviewReducer from '../features/Cropview/cropviewSlice';
import zoneInfoReducer from '../features/Mapview/zoneInfoSlice';
import coverageModalReducer from '../features/Cropview/coverageModalSlice';
import inspectionFormReducer from '../features/InspectionForm/inspectionFormSlice';
import shopviewReducer from '../features/Shopview/shopviewSlice';
import upcomingModalReducer from '../features/Shopview/Upcoming/upcomingModalSlice';
import scheduleModalReducer from '../features/Shopview/Schedule/scheduleModalSlice';
import historyModalReducer from '../features/Shopview/History/historyModalSlice';
import diagnosticsModalReducer from '../features/Shopview/Diagnostics/diagnosticsModalSlice';
import inspectionReportsModalReducer from '../features/Shopview/InspectionReports/inspectionReportsModalSlice';
import searchBarReducer from '../components/Topbar/searchBarSlice';
import vehicleInfoReducer from '../features/Settings/Assets/VehicleInfo/vehicleInfoSlice';
import settingsReducer from '../features/Settings/settingsSlice';
import dashboardReducer from '../features/Statsview/dashboardSlice';

const combinedReducer = combineReducers({
  app: appReducer,
  framework: frameworkReducer,
  cabview: cabviewReducer,
  cropview: cropviewReducer,
  coverageModal: coverageModalReducer,
  inspectionForm: inspectionFormReducer,
  shopview: shopviewReducer,
  upcomingModal: upcomingModalReducer,
  scheduleModal: scheduleModalReducer,
  historyModal: historyModalReducer,
  diagnosticsModal: diagnosticsModalReducer,
  inspectionReportsModal: inspectionReportsModalReducer,
  searchBar: searchBarReducer,
  zoneinfo: zoneInfoReducer,
  vehicleinfo: vehicleInfoReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
});

const rootReducer = (state, action) => {
  // If receive action 'RESET', set store to initial state
  if (action.type === 'RESET') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'cropview.zonesData',
          'cropview.zoneAnalytics',
          'cropview.equipmentAnalytics',
          'cropview.inZonePathData',
          'cropview.outOfZonePathData',
        ],
      },
    });
  },
});
