import {createSlice} from '@reduxjs/toolkit';

const cropviewSlice = createSlice({
  name: 'cropview',
  initialState: {
    loading: true,
    dates: {start: null, end: null, max: null},
    zonesData: [],
    zonesDict: {},
    geoFences: [],
    geoFenceIntelliBlockDict: {},
    parentFieldLookup: {},
    geojsonLayerData: [],

    // Only for testing
    testScatterPlotData: [{position: [-122.45, 37.78], size: 500, color: [0, 255, 0]}],

    vehicleSNDict: {},
    implementSNDict: {},
    taskConfigIdDict: {},
    zoneAnalytics: [],
    equipmentAnalytics: [],
    displayedTable: 'block',
    displayedColumn: 'coverage',
    sortMethod: 'name',
    mapView: 1,
    zoneZoom: '',
    todayOnly: false,
    editCropview: false,
    editCropviewValues: [],
    editCropviewRecValues: [],
    vehicleZoom: '',
    lastTripTaskEndTime: '',
    reiActiveDict: {},
    recList: [],
    refreshRequired: false,
    taskAcreageCapping: false,
    taskAcreageCapTracker: {},
    taskAcreageCapTrackerTask: '',
    zoneNameToIntelliBlockNums: {},
    sprayCompleteDict: [],
    gpsLoaded: false,
    pathsLoading: true,
    pathQueryStatus: {
      success: true,
      pathDataTooLarge: false,
    },
    inZonePathData: [],
    outOfZonePathData: [],
    vehicleLatestLocationDict: {},
    vehicleSerialNumbersInDisplayedAnalytics: [],
    pathsVehicleDays: 0,
    numDaysInReq: 0,
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updatePathsVehicleDays(state, action) {
      state.pathsVehicleDays = action.payload;
    },
    updateNumDaysInReq(state, action) {
      state.numDaysInReq = action.payload;
    },
    updatePathsLoading(state, action) {
      state.pathsLoading = action.payload;
    },
    updateVehicleSerialNumbersInDisplayedAnalytics(state, action) {
      state.vehicleSerialNumbersInDisplayedAnalytics = action.payload;
    },
    updateCoverageData(state, action) {
      state.vehicleSNDict = action.payload.vehicleSNDict;
      state.implementSNDict = action.payload.implementSNDict;
      state.zonesData = action.payload.zonesData;
      state.geoFences = action.payload.geoFences;
      state.taskConfigIdDict = action.payload.taskConfigIdDict;
      state.zoneAnalytics = action.payload.zoneAnalytics;
      state.equipmentAnalytics = action.payload.equipmentAnalytics;
      state.recList = action.payload.recList;

      const zonesData = action.payload.zonesData;

      // Create zones dict
      const zonesDict = {
        blocks: {},
        fields: {},
        regions: {},
      };
      zonesData.blocks.forEach((block) => {
        zonesDict.blocks[block.block_id] = block;
      });
      zonesData.fields.forEach((field) => {
        zonesDict.fields[field.field_id] = field;
      });
      zonesData.regions.forEach((region) => {
        zonesDict.regions[region.region_id] = region;
      });
      state.zonesDict = zonesDict;

      const fieldIdToFieldName = {};
      const blockIdToFieldId = {};
      const zoneNameToIntelliBlockNums = {
        'region': {},
        'field': {},
        'block': {},
      };
      const fieldIdToRegionId = {};
      const regionIdtoRegionName = {};
      for (let i = 0; i < zonesData.regions.length; i++) {
        regionIdtoRegionName[zonesData.regions[i].region_id] = zonesData.regions[i].region_name;
      }
      for (let i = 0; i < zonesData.fields.length; i++) {
        fieldIdToFieldName[zonesData.fields[i].field_id] = zonesData.fields[i].field_name;
        fieldIdToRegionId[zonesData.fields[i].field_id] = zonesData.fields[i].parent_region_id;
      }
      for (let i = 0; i < zonesData.blocks.length; i++) {
        blockIdToFieldId[zonesData.blocks[i].block_id] = zonesData.blocks[i].parent_field_id;
        const curFieldName = fieldIdToFieldName[zonesData.blocks[i].parent_field_id];
        const curBlockRegionId = fieldIdToRegionId[zonesData.blocks[i].parent_field_id];
        const curRegionName = regionIdtoRegionName[curBlockRegionId];

        // Append current blocks intelliblocks to the overall block mapping
        zoneNameToIntelliBlockNums['block'][zonesData.blocks[i].block_name] = zonesData.blocks[i].intelliblock_nums;

        // Append current blocks intelliblocks to the overall field mapping
        if (!zoneNameToIntelliBlockNums['field'].hasOwnProperty(curFieldName)) {
          zoneNameToIntelliBlockNums['field'][curFieldName] = [];
        }
        zoneNameToIntelliBlockNums['field'][curFieldName] = [
          ...zoneNameToIntelliBlockNums['field'][curFieldName],
          ...zoneNameToIntelliBlockNums['block'][zonesData.blocks[i].block_name],
        ];

        // Append current blocks intelliblocks to the overall region mapping
        if (!zoneNameToIntelliBlockNums['region'].hasOwnProperty(curRegionName)) {
          zoneNameToIntelliBlockNums['region'][curRegionName] = [];
        }
        zoneNameToIntelliBlockNums['region'][curRegionName] = [
          ...zoneNameToIntelliBlockNums['region'][curRegionName],
          ...zoneNameToIntelliBlockNums['block'][zonesData.blocks[i].block_name],
        ];
      }
      state.zoneNameToIntelliBlockNums = zoneNameToIntelliBlockNums;
      state.parentFieldLookup = blockIdToFieldId;

      // Collect a dictionary of geoFences and geoFenceAttributes based on intelliblock_num
      const geoFenceIntelliBlockDict = {};
      for (let i = 0; i < state.geoFences.length; i++) {
        if (typeof state.geoFences[i].properties.block_id === 'undefined') {
          continue;
        }

        if (
          !Object.prototype.hasOwnProperty.call(
            geoFenceIntelliBlockDict,
            state.geoFences[i].properties.intelliblock_num
          )
        ) {
          geoFenceIntelliBlockDict[state.geoFences[i].properties.intelliblock_num] = state.geoFences[i];
        }
      }
      state.geoFenceIntelliBlockDict = geoFenceIntelliBlockDict;
    },
    updateClearData(state, action) {
      state.zoneAnalytics = [];
      state.equipmentAnalytics = [];
    },
    updateDisplayedTable(state, action) {
      state.displayedTable = action.payload;
    },
    updateDisplayedColumn(state, action) {
      state.displayedColumn = action.payload;
    },
    updateSortMethod(state, action) {
      state.sortMethod = action.payload;
    },
    updateMapView(state, action) {
      // 0: Only table, 1: Table and map, 2: Only map
      const mapSetting = action.payload;
      if (mapSetting == undefined) {
        state.mapView = (state.mapView + 1) % 3;
      } else {
        state.mapView = mapSetting;
      }
    },
    updateZoneZoom(state, action) {
      state.zoneZoom = action.payload;
    },
    updateVehicleZoom(state, action) {
      state.vehicleZoom = action.payload;
    },
    updateTodayOnly(state, action) {
      state.todayOnly = action.payload;
    },
    updateEditCropview(state, action) {
      const editCropviewBool = action.payload;

      state.editCropview = editCropviewBool;
      if (!editCropviewBool) {
        state.editCropviewValues = [];
        state.editCropviewRecValues = [];
      }
    },
    updateEditCropviewValues(state, action) {
      state.editCropviewValues.push(action.payload);
    },
    updateEditCropviewRecValues(state, action) {
      state.editCropviewRecValues.push(action.payload);
    },
    updateDates(state, action) {
      state.dates = action.payload;
    },
    updateLastTripTaskEndTime(state, action) {
      state.lastTripTaskEndTime = action.payload;
    },
    updateReiActive(state, action) {
      const reiList = action.payload;
      const reiActiveDict = {};

      reiList.map((rei) => {
        reiActiveDict[rei.zoneId] = rei;
      });

      state.reiActiveDict = reiActiveDict;
    },
    updateRefreshRequired(state, action) {
      state.refreshRequired = action.payload;
    },
    updateTaskAcreageCapping(state, action) {
      state.taskAcreageCapping = action.payload;
    },
    updateTaskAcreageCapTracker(state, action) {
      state.taskAcreageCapTracker = action.payload;
    },
    updateTaskAcreageCapTrackerTask(state, action) {
      state.taskAcreageCapTrackerTask = action.payload;
    },
    updateSpraysComplete(state, action) {
      state.sprayCompleteDict = action.payload;
    },
    updateGpsLoaded(state, action) {
      state.gpsLoaded = action.payload;
    },
    updatePathQueryStatus(state, action) {
      state.pathQueryStatus = action.payload;
    },
    updateInZonePathData(state, action) {
      state.inZonePathData = action.payload;
    },
    updateVehicleLatestLocationDict(state, action) {
      state.vehicleLatestLocationDict = action.payload;
    },
    updateOutOfZonePathData(state, action) {
      state.outOfZonePathData = action.payload;
    },
  },
});

export const {
  updateLoading,
  updateDates,
  updateCoverageData,
  updateClearData,
  updateDisplayedTable,
  updateDisplayedColumn,
  updateSortMethod,
  updateMapView,
  updateZoneZoom,
  updateVehicleZoom,
  updateTodayOnly,
  updateEditCropview,
  updateEditCropviewValues,
  updateEditCropviewRecValues,
  updateLastTripTaskEndTime,
  updateReiActive,
  updateRefreshRequired,
  updateTaskAcreageCapping,
  updateTaskAcreageCapTracker,
  updateTaskAcreageCapTrackerTask,
  updateSpraysComplete,
  updateGpsLoaded,
  updatePathsLoading,
  updatePathQueryStatus,
  updateInZonePathData,
  updateVehicleLatestLocationDict,
  updateOutOfZonePathData,
  updateVehicleSerialNumbersInDisplayedAnalytics,
  updatePathsVehicleDays,
  updateNumDaysInReq,
} = cropviewSlice.actions;
export default cropviewSlice.reducer;
