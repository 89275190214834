// Import for framework tools
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getServiceData} from './shopviewSlice';
import {sendGAPageview} from '../../app/utils';

// Import dependent components
import {Framework} from '../../components/Framework';
import {UpcomingTab} from './Upcoming/UpcomingTab';
import {HistoryTab} from './History/HistoryTab';
import {ScheduleTab} from './Schedule/ScheduleTab';
import {DiagnosticsTab} from './Diagnostics/DiagnosticsTab';
import {InspectionReportsTab} from './InspectionReports/InspectionReportsTab';
import {UpcomingDataModal} from './Upcoming/UpcomingDataModal';
import {CompleteTaskModal} from './Upcoming/CompleteTaskModal';
import {ScheduleDataModal} from './Schedule/ScheduleDataModal';
import {NewTaskModal} from './Schedule/NewTaskModal';
import {EditTaskModal} from './Schedule/EditTaskModal';
import {HistoryDataModal} from './History/HistoryDataModal';
import {AddHistoryModal} from './History/AddHistoryModal';
import {EditHistoryModal} from './History/EditHistoryModal';
import {DiagnosticsDataModal} from './Diagnostics/DiagnosticsDataModal';
import {InspectionReportsModal} from './InspectionReports/InspectionReportsModal';
import {Tabs} from '../../components/Tabs';
import {TabContent} from '../../components/TabContent';

function Shopview() {
  const dispatch = useDispatch();

  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  useEffect(() => {
    document.title = 'IntelliCulture | ShopView';
    sendGAPageview(document.title);
    dispatch(getServiceData());
  }, []);

  const [linkedInspectionReportId, setLinkedInspectionReportId] = useState('');

  // Default shopview Tab names, icons, and contents
  const shopviewTabs = authenticationStatus.shopviewEnabled ? ['Services', 'History', 'Schedule'] : [];
  const shopviewIcons = authenticationStatus.shopviewEnabled
    ? ['fa-solid fa-wrench', 'fa-solid fa-clock-rotate-left', 'fa-solid fa-calendar-days']
    : [];
  const shopviewContent = authenticationStatus.shopviewEnabled
    ? [<UpcomingTab key='services' />, <HistoryTab key='history' />, <ScheduleTab key='schedule' />]
    : [];

  // Diagnostics Tab added base on customerSettings
  if (customerSettings.shopview.diagnosticsTabEnabled) {
    shopviewTabs.push('Diagnostics');
    shopviewIcons.push('fa-solid fa-stethoscope');
    shopviewContent.push(<DiagnosticsTab key='diagnostics' />);
  }

  // Inspection Report Tab added base on customerSettings
  if (authenticationStatus.inspectionEnabled) {
    shopviewTabs.push('Inspections');
    shopviewIcons.push('fa-solid fa-list-check');
    shopviewContent.push(<InspectionReportsTab key='inspections' />);
  }

  return (
    <React.Fragment>
      <Framework activePage='shopview' pageName='ShopView'>
        <div className='container-fluid' id='tab-wrapper'>
          <div className='row'>
            <div className='col-12'>
              <Tabs tabs={shopviewTabs} icons={shopviewIcons} />
            </div>
          </div>
          <TabContent content={shopviewContent} />
        </div>
      </Framework>
      <UpcomingDataModal />
      <CompleteTaskModal setLinkedInspectionReportId={setLinkedInspectionReportId} />
      <ScheduleDataModal />
      <NewTaskModal />
      <EditTaskModal setLinkedInspectionReportId={setLinkedInspectionReportId} />
      <HistoryDataModal />
      <AddHistoryModal setLinkedInspectionReportId={setLinkedInspectionReportId} />
      <EditHistoryModal />
      <DiagnosticsDataModal />
      <InspectionReportsModal
        linkedInspectionReportId={linkedInspectionReportId}
        setLinkedInspectionReportId={setLinkedInspectionReportId}
      />
    </React.Fragment>
  );
}

export {Shopview};
