// Import for framework tools
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {updateSmallScreen} from './frameworkSlice';
import {customTheme} from '../../app/utils';
// Import dependent components
import {Sidebar} from '../Sidebar';
import {Topbar} from '../Topbar';
import {createTheme, ThemeProvider} from '@mui/material/styles';
function Framework(props) {
  const dispatch = useDispatch();
  const theme = createTheme(customTheme);

  const [sideMenuOpened, setSideMenuOpened] = useState(false);

  function handleSetSideMenuOpened(state = undefined) {
    setSideMenuOpened((currentState) => {
      return state == undefined ? !currentState : state;
    });
  }

  useEffect(() => {
    function setScreenSize() {
      if (window.innerWidth <= 767) {
        dispatch(updateSmallScreen(true));
      } else {
        dispatch(updateSmallScreen(false));
      }
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', setScreenSize);
    setScreenSize();
    return () => {
      // Remove event listener when component is unmounted
      window.removeEventListener('resize', setScreenSize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div id='page-top'>
        <div id='wrapper'>
          <Sidebar
            activePage={props.activePage}
            sideMenuOpened={sideMenuOpened}
            handleSetSideMenuOpened={handleSetSideMenuOpened}
          />
          <div id='content-wrapper'>
            <div id='content'>
              <Topbar
                text={props.pageName}
                search={props.topBarSearch != false}
                handleSetSideMenuOpened={handleSetSideMenuOpened}
              />
              {props.children}
            </div>
            {props.footer && (
              <footer className='sticky-footer bg-white'>
                <div className='container my-auto'>
                  <div className='copyright text-center my-auto'></div>
                </div>
              </footer>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export {Framework};
