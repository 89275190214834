import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getCustomerAndUserInfo} from '../../../appSlice';
import {getSettingsData} from '../settingsSlice';
import {searchFind, modalBoxStyle, hideCell, squareButton, generateCsv, fetchPostAuthSafe} from '../../../app/utils';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';
import {
  ModalSubmitting,
  SettingsSwitch,
  SettingsSearchSelect,
  ViewOnlyText,
} from '../../../components/GeneralComponents';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  Box,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';

function BtTagsTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.loading;
  });
  const btTags = useSelector((state) => {
    return state.settings.btTags;
  });
  const labels = useSelector((state) => {
    return state.settings.labels;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [implementsWithLabels, setImplementsWithLabels] = useState([]);
  const [implementWarning, setImplementWarning] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    tagId: [],
    implementName: [],
    tagStatus: ['Active', 'Archived'],
  });

  const [filters, setFilters] = useState({
    tagId: [],
    implementName: [],
    tagStatus: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTagId, setModalTagId] = useState('');

  const [modalFormValues, setModalFormValues] = useState({
    id: '',
    implementSN: '',
    archived: false,
    lastCommuncation: '',
    batteryLevel: '',
  });

  useEffect(async () => {
    const newTag = !modalTagId || modalTagId == 'TBD';
    const tagData = btTags.find((tag) => {
      return tag.data.id == modalTagId;
    });

    setModalFormValues({
      id: newTag ? 'TBD' : tagData.data.id,
      databaseLabel: newTag ? 'TBD' : tagData.data.databaseLabel,
      implementSN: newTag ? '' : tagData.data.implementSN,
      lastCommunication: newTag ? '' : tagData.data.lastCommunication,
      batteryLevel: newTag ? '' : tagData.data.batteryLevel,
    });
  }, [modalTagId]);

  useEffect(async () => {
    const newImpWithLabelsList = [];
    labels.forEach((labelDoc) => {
      if (labelDoc.data.assetType == 'implement' && labelDoc.data.assetId) {
        newImpWithLabelsList.push(labelDoc.data.assetId);
      }
    });
    setImplementsWithLabels(newImpWithLabelsList);
  }, [labels]);

  useEffect(async () => {
    const tempTableData = btTags.filter((tag) => {
      let implementName = '';
      const implement = implementSNDict[tag.data.implementSN];
      if (implement) {
        implementName = implement.name;
      }
      const tagInFilter = filterRow(implementName, tag);
      return tagInFilter;
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);
  }, [btTags, implementSNDict, filters, searchText]);

  useEffect(async () => {
    const tagFilterOptions = [];
    const implementFilterOptions = [];

    btTags.forEach((tag) => {
      tagFilterOptions.push(`${tag.data.databaseLabel}`);

      const implement = implementSNDict[tag.data.implementSN];
      if (implement) {
        implementFilterOptions.push(implement.name);
      }
    });
    setFilterOptions({
      ...filterOptions,
      implementName: implementFilterOptions,
      tagId: tagFilterOptions,
    });
  }, [btTags, implementSNDict]);

  useEffect(async () => {
    setImplementWarning(implementsWithLabels.includes(modalFormValues.implementSN));
  }, [modalFormValues, implementsWithLabels]);

  const handleModalChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setModalFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  async function handleModalSubmit() {
    setSubmitting(true);

    // Create doc for asset label
    const updateDoc = {
      id: modalFormValues.id,
      implementSN: modalFormValues.implementSN,
      archived: modalFormValues.archived,
    };

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(updateDoc),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateBtTag',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    } else if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    dispatch(getCustomerAndUserInfo());
    dispatch(getSettingsData());
    setModalOpen(false);
    setSubmitting(false);
    setModalTagId('');
  }

  function downloadCsv() {
    // Push the Asset Label data into the csv data table
    const csvList = [];
    btTags.forEach(function (tag) {
      const id = tag.data.id;
      let databaseLabel = '';
      let labelledId = id;
      if (typeof tag.data.databaseLabel != 'undefined') {
        databaseLabel = tag.data.databaseLabel;
        labelledId += `-${databaseLabel}`;
      }

      let implementName = '';
      const implement = implementSNDict[tag.data.implementSN];
      if (implement) {
        implementName = implement.name;
      }

      // Compile Data into array in the same order the CSV header listed below
      const tagData = [databaseLabel, labelledId, tag.data.archived, implementName];
      csvList.push(tagData);
    });

    const filename = 'BtTags';
    const csvHeaders = ['Label Number', 'Display Text', 'Archived Status', 'Implement Name'];

    generateCsv(filename, csvHeaders, csvList);
  }

  function sortByBooleanValue(a, b) {
    // If "isTrue" is true, the item should come before (lower index) the other item (b).
    // If "isTrue" is false, the item should come after (higher index) the other item (b).
    // return implementSNDict[a].archived === implementSNDict[b].archived ? 0 : implementSNDict[a].archived ? 1 : -1;
    if (implementSNDict[a].archived) {
      return 1;
    }
    if (implementSNDict[a].archived === implementSNDict[b].archived) {
      return implementSNDict[a].name.localeCompare(implementSNDict[b].name);
    }
    return 0;
  }

  function generateModal() {
    const implementsKeysList = Object.keys(implementSNDict);
    implementsKeysList.sort(sortByBooleanValue);

    // Determine asset options to display
    let implementOptions = [{value: '', label: '----'}];
    implementOptions = implementOptions.concat(
      implementsKeysList.map((impSN) => {
        return {
          value: impSN,
          label: implementSNDict[impSN].name + (implementSNDict[impSN].archived ? ' - Archived' : ''),
        };
      })
    );

    // Determine last communication and battery level
    let lastCommunication = '';
    let batteryLevel = '';
    if (modalFormValues.lastCommunication) {
      // tag.data.lastCommunication should be a string in ISO format
      lastCommunication = DateTime.fromISO(modalFormValues.lastCommunication)
        .setZone(customerSettings.general.timeZone)
        .toLocaleString(DateTime.DATETIME_MED);
      batteryLevel = `${modalFormValues.batteryLevel}%`;
    }

    const viewOnly = !userSettings.roleAccess['bluetoothManagement'];

    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setModalTagId('');
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='overflow-auto'
      >
        <Box sx={modalBoxStyle}>
          {submitting ? (
            <ModalSubmitting />
          ) : (
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <h3>
                <strong>BT Tag Settings</strong>
              </h3>
              {viewOnly && <ViewOnlyText />}
              <Table aria-label='simple table'>
                <TableBody>
                  <TableRow>
                    <TableCell width='50%'>Code ID</TableCell>
                    <TableCell>{`${modalFormValues.id}` + `-${modalFormValues.databaseLabel}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width='50%'>Last Communication</TableCell>
                    <TableCell>{lastCommunication}</TableCell>
                  </TableRow>
                  {userSettings.role == 'ic_admin' && (
                    <TableRow>
                      <TableCell width='50%'>Battery Level</TableCell>
                      <TableCell>{batteryLevel}</TableCell>
                    </TableRow>
                  )}
                  <SettingsSearchSelect
                    label='Implement'
                    setting='implementSN'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                    options={implementOptions}
                    error={implementWarning}
                    helperText={implementWarning ? 'Implement already has a QR label assigned' : ''}
                  />
                  <SettingsSwitch
                    label='Deactivate Tag'
                    setting='archived'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                  />
                </TableBody>
              </Table>

              <div className='flex-row my-3 col-12 px-1'>
                <Button
                  variant='ic-button'
                  color='secondary'
                  className='mx-1'
                  onClick={() => {
                    setModalOpen(false);
                    setModalTagId('');
                  }}
                >
                  Cancel
                </Button>
                {!viewOnly && (
                  <Button
                    variant='ic-button'
                    color='primary'
                    className='mx-1'
                    onClick={handleModalSubmit}
                    disabled={implementWarning}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  function filterRow(implementName, tag) {
    const tagStatus = tag.data.archived ? 'Archived' : 'Active';
    const tagIdDisplay = `${tag.data.databaseLabel}`;

    // Convert Properties to string for search
    const rowObj = {
      ...tag.data,
      implementName: implementName,
      status: tagStatus,
    };
    // remove 'archived' properties from rowObj so searching 'archived' does not show both status
    delete rowObj.archived;

    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const implementNameInFilter = filters.implementName.includes(implementName) || filters.implementName.length == 0;
    const tagIdInFilter = filters.tagId.includes(tagIdDisplay) || filters.tagId.length == 0;
    const tagStatusInFilter = filters.tagStatus.includes(tagStatus) || filters.tagStatus.length == 0;
    const tagInFilter = implementNameInFilter && tagIdInFilter && tagStatusInFilter;

    return tagInFilter && search;
  }

  function generateTable() {
    let colorFlip = false;
    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Tag ID'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#bt-tag-id-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['tagId'].length > 0 && '#4e73df'}} />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='bt-tag-id-select'
                    name={'tagId'}
                    multiple={true}
                    search={true}
                    value={filters['tagId']}
                    options={filterOptions['tagId']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Implement'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#bt-tag-implement-name-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['implementName'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='bt-tag-implement-name-select'
                    name={'implementName'}
                    multiple={true}
                    search={true}
                    value={filters['implementName']}
                    options={filterOptions['implementName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={hideCell}>Last Communication</TableCell>
              {userSettings.role == 'ic_admin' && <TableCell sx={hideCell}>Battery Level</TableCell>}
              <TableCell sx={hideCell}>
                <TableHeadFilterButtonTitleWrapper title={'Status'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#bt-tag-status-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['tagStatus'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='bt-tag-status-select'
                    name={'tagStatus'}
                    multiple={true}
                    search={true}
                    value={filters['tagStatus']}
                    options={filterOptions['tagStatus']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((tag) => {
                  // Determine implement name
                  let implementName = '';
                  const implement = implementSNDict[tag.data.implementSN];
                  if (implement) {
                    implementName = implement.name;
                  }

                  // Determine last communication and battery level
                  let lastCommunication = '';
                  let batteryLevel = '';
                  if (tag.data.lastCommunication) {
                    // tag.data.lastCommunication should be a string in ISO format
                    lastCommunication = DateTime.fromISO(tag.data.lastCommunication)
                      .setZone(customerSettings.general.timeZone)
                      .toLocaleString(DateTime.DATETIME_MED);
                    batteryLevel = `${tag.data.batteryLevel}%`;
                  }

                  colorFlip = !colorFlip;
                  return (
                    <TableRow key={tag.data.id} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                      <TableCell>{`${tag.data.id}-${tag.data.databaseLabel}`}</TableCell>
                      <TableCell>{implementName}</TableCell>
                      <TableCell sx={hideCell}>{lastCommunication}</TableCell>
                      {userSettings.role == 'ic_admin' && <TableCell sx={hideCell}>{batteryLevel}</TableCell>}
                      <TableCell sx={hideCell}>
                        {tag.data.archived ? (
                          <Chip label='Archived' color='error' />
                        ) : (
                          <Chip label='Active' color='success' />
                        )}
                      </TableCell>
                      <TableCell>
                        <button
                          className='btn btn-light'
                          style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                          onClick={() => {
                            setModalOpen(true);
                            setModalTagId(tag.data.id);
                          }}
                        >
                          <FontAwesomeIcon icon='fa fa-edit' />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function generateMenu() {
    return (
      <React.Fragment>
        <div className='pt-2 pb-0'>
          <h5 style={{color: 'black'}}>Bluetooth Tags Management</h5>
          <div>
            <Button
              sx={squareButton}
              variant='ic-button'
              color='secondary'
              className='ml-1'
              title='Download CSV'
              onClick={downloadCsv}
            >
              <FontAwesomeIcon icon='fas fa-download' />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {generateModal()}
      <TabMenuTableWrapper
        menu={generateMenu()}
        table={generateTable()}
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
    </React.Fragment>
  );
}

export {BtTagsTab};
