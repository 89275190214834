import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createPortal} from 'react-dom';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {Tailselect} from '../../../components/Tailselect';
import {searchFind, deepCopy} from '../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setInitialValues} from './scheduleModalSlice';
import {Table, TableHead, TableBody, TableCell, TableRow, IconButton} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScheduleTable(props) {
  const dispatch = useDispatch();

  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    vehicles: [],
    tasks: [],
  });

  useEffect(() => {
    filterTableRows(deepCopy(props.tableData));
  }, [props.tableData, filters, searchText]);

  function rowFilter(rowData, row) {
    const rowTaskNames = rowData.serviceTaskList.map((serviceTaskData) => {
      return serviceTaskData.task;
    });

    const vehiclesInFilter = filters.vehicles.includes(rowData.vehicle) || filters.vehicles.length == 0;
    const taskInFilter =
      filters.tasks.some((item) => {
        return rowTaskNames.includes(item);
      }) || filters.tasks.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return vehiclesInFilter && taskInFilter && search;
  }

  function filterTableRows(allTableData) {
    const tempTableData = allTableData.filter((rowData) => {
      return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    // Filter  down each nested table to only contain services within the filter
    for (let i = 0; i < tempTableData.length; i++) {
      tempTableData[i].serviceTaskList = tempTableData[i].serviceTaskList.filter((serviceTaskItem) => {
        return filters.tasks.includes(serviceTaskItem.task) || filters.tasks.length == 0;
      });
    }

    setTableData(tempTableData);
  }

  function handleFilters(select) {
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell sx={{width: '30%'}}>
            <TableHeadFilterButtonTitleWrapper title={'Equipment'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#shopview-schedule-vehicle-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.vehicles.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='shopview-schedule-vehicle-select'
                name={'vehicles'}
                multiple={true}
                search={true}
                value={filters.vehicles}
                options={props.filterOptions.vehicles}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={{width: '40%'}}>
            <TableHeadFilterButtonTitleWrapper title={'Service'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#shopview-schedule-task-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.tasks.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='shopview-schedule-task-select'
                name={'tasks'}
                multiple={true}
                search={true}
                value={filters.tasks}
                options={props.filterOptions.tasks}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' sx={{width: '25%'}}>
            Interval
          </TableCell>
          <TableCell className='d-none d-md-table-cell' sx={{width: '5%'}}>
            {/* Edit Button */}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function CollapsibleRow(props) {
    const {rowData, colorFlip} = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => {
                return setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className='text-secondary font-weight-bold'>{rowData.vehicle}</TableCell>
          <TableCell className='text-secondary font-weight-bold'>
            {`${rowData.serviceTaskList.length} services`}
          </TableCell>
          <TableCell className='d-none d-md-table-cell' />
          <TableCell className='d-none d-md-table-cell' />
        </TableRow>

        {rowData.serviceTaskList.map((serviceTaskData, taskIdx) => {
          return (
            <TableRow
              key={taskIdx}
              className={open ? '' : 'd-none'}
              sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}
            >
              {smallScreen ? (
                <TableCell>
                  <button
                    className='btn btn-light'
                    style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                    data-toggle='modal'
                    data-target='#shopview-edit-task-modal'
                    onClick={() => {
                      dispatch(setInitialValues(serviceTaskData));
                    }}
                  >
                    <FontAwesomeIcon icon='fa fa-edit' />
                  </button>
                </TableCell>
              ) : (
                <TableCell />
              )}
              <TableCell />
              <TableCell className='text-secondary font-weight-bold'>
                {serviceTaskData.task}
                <div
                  className='text-secondary font-weight-normal d-md-none'
                  dangerouslySetInnerHTML={{__html: serviceTaskData.intervalString}}
                />
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div className='text-secondary' dangerouslySetInnerHTML={{__html: serviceTaskData.intervalString}} />
              </TableCell>
              <TableCell align='right' className='d-none d-md-table-cell'>
                <button
                  className='btn btn-light'
                  style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                  data-toggle='modal'
                  data-target='#shopview-edit-task-modal'
                  onClick={() => {
                    dispatch(setInitialValues(serviceTaskData));
                  }}
                >
                  <FontAwesomeIcon icon='fa fa-edit' />
                </button>
              </TableCell>
            </TableRow>
          );
        })}
      </React.Fragment>
    );
  }

  function generateTableBody() {
    if (props.loading) {
      return (
        <TableBody id='table-body'>
          <TableRow>
            <TableCell colSpan={100}>
              <div className='m-2'>
                <div className='loader mx-auto'></div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let colorFlip = false;
    const paginatedData =
      rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData;

    return (
      <TableBody>
        {paginatedData.map((rowData, index) => {
          colorFlip = !colorFlip;

          const row = <CollapsibleRow key={index} rowData={rowData} colorFlip={colorFlip} />;
          return row;
        })}
      </TableBody>
    );
  }

  return (
    <div className='table-responsive'>
      <TabMenuTableWrapper
        useLoadingSpinner={true}
        loading={props.loading}
        table={
          <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
            {generateTableHeaders()}
            {generateTableBody()}
          </Table>
        }
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
    </div>
  );
}

export {ScheduleTable};
